import React, { useEffect } from "react";
import Layout from "./layout/layout.component";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { setCurrentUser, usersLoginLoading } from "./redux/auth/auth.action";
import { addSnapshotsListners } from "./redux/app-settings/app-settings.action";
import { createUserDataForRedux, userAuthMerged } from "./utils/auth.utils";
import firebase, { addUserNameEmailOAuth } from "./firebase/firebaseConfig";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useDispatch();
  //useeffect that clears the local storage when the user closes the tab
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log("event");
      localStorage.clear();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const { mergeAccount, isZensuranceEmployee } = await userAuthMerged();
        console.log("check the user", mergeAccount, isZensuranceEmployee);
        const userRef = await addUserNameEmailOAuth(userAuth);
        dispatch(usersLoginLoading(true));
        let userSnapshot = userRef.onSnapshot(async (snapShot) => {
          const userData = await createUserDataForRedux({
            userAuth,
            snapShot,
            mergeAccount,
            isZensuranceEmployee,
          });
          dispatch(setCurrentUser(userData));
        });
        dispatch(
          addSnapshotsListners({
            type: "user-snapshot",
            snapshot: userSnapshot,
          })
        );
      } else {
        dispatch(
          setCurrentUser({
            email: "",
            displayName: "",
            authLoaded: true,
            authenticated: false,
          })
        );
      }
    });
  }, [dispatch]);

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {})
      .catch((error) => {
        console.log("sso error", error);
      });
  }, []);

  return (
    <div className="App">
      <div className="main-app-container">
        <Routes>
          <Route path="*" element={<Layout />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
