import firebase from "../../firebase/firebaseConfig";
import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const createBroker =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "CREATE_BROKER_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const addBrokerIntoFirebase = firebase
        .functions()
        .httpsCallable("addBrokerIntoFirebase");
      const result = await addBrokerIntoFirebase(data);
      if (result.data.success) {
        dispatch({
          type: "CREATE_BROKER_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "CREATE_BROKER_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "CREATE_BROKER_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };
export const createBrokersInBulk =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "CREATE_BROKER_IN_BULK_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const addingBrokersInBulk = firebase
        .functions()
        .httpsCallable("addingBrokersInBulk");
      const result = await addingBrokersInBulk(data);
      console.log("result", result);
      if (result.data.success) {
        dispatch({
          type: "CREATE_BROKER_IN_BULK_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "CREATE_BROKER_IN_BULK_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "CREATE_BROKER_IN_BULK_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const updateAllBrokerDetails =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const updateAllTotals = firebase
        .functions()
        .httpsCallable("updateAllTotalCount");
      const result = await updateAllTotals(data);
      console.log("All all Totals Result", result);
    } catch (err) {
      console.log(err.message);
    }
  };

export const fetchBrokersDataForManagerRole = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "GET_ALL_BROKERS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const { currentUser } = getState().user;
    const { orgId } = currentUser;
    const assignedDepartments = currentUser.departmentsAssigned;

    const brokerRef = firestore
      .collection("users")
      .where("orgId", "==", orgId)
      .where("status", "==", "active")
      .where("department", "in", assignedDepartments);

    try {
      let joinedBrokerSnapshot = brokerRef.onSnapshot((response) => {
        let allBrokerArray = [];

        if (response.docs.length) {
          for (const doc of response.docs) {
            const data = doc.data();

            const finalDoc = {
              id: doc.id,
              ...data,
            };
            allBrokerArray.push(finalDoc);
          }

          dispatch({
            type: "ALL_BROKERS",
            payload: allBrokerArray,
          });
          dispatch({
            type: "GET_ALL_BROKERS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "ALL_BROKERS",
            payload: [],
          });
          dispatch({
            type: "GET_ALL_BROKERS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
      });
      dispatch(
        addSnapshotsListners({
          type: "joined-broker-snapshot",
          snapshot: joinedBrokerSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_BROKERS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};
export const fetchBrokersData = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "GET_ALL_BROKERS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const orgId = getState().user.currentUser.orgId;
    const brokerRef = firestore
      .collection("users")
      .where("orgId", "==", orgId)
      .where("status", "==", "active");

    try {
      let joinedBrokerSnapshot = brokerRef.onSnapshot((response) => {
        let allBrokerArray = [];

        if (response.docs.length) {
          for (const doc of response.docs) {
            const data = doc.data();

            const finalDoc = {
              id: doc.id,
              ...data,
            };
            allBrokerArray.push(finalDoc);
          }

          dispatch({
            type: "ALL_BROKERS",
            payload: allBrokerArray,
          });
          dispatch({
            type: "GET_ALL_BROKERS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "ALL_BROKERS",
            payload: [],
          });
          dispatch({
            type: "GET_ALL_BROKERS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
      });
      dispatch(
        addSnapshotsListners({
          type: "joined-broker-snapshot",
          snapshot: joinedBrokerSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_BROKERS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};
export const fetchFormerBrokers = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "GET_ALL_FORMER_BROKER_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const orgId = getState().user.currentUser.orgId;
    const formerBrokerRef = firestore
      .collection("users")
      .where("role", "!=", "admin")
      .where("orgId", "==", orgId)
      .where("status", "==", "former");

    try {
      // let allBrokerArray = [];

      // if (brokerRef.docs.length) {
      //   for (const doc of brokerRef.docs) {
      //     const data = doc.data();
      //     const finalDoc = {
      //       id: doc.id,
      //       ...data,
      //     };
      //     allBrokerArray.push(finalDoc);
      //   }
      // }
      let formerBrokerSnapshot = formerBrokerRef.onSnapshot((response) => {
        let formerBrokerArray = [];

        if (response.docs.length) {
          for (const doc of response.docs) {
            const data = doc.data();

            const finalDoc = {
              id: doc.id,
              ...data,
            };
            formerBrokerArray.push(finalDoc);
          }
          dispatch({
            type: "ALL_FORMER_BROKERS",
            payload: formerBrokerArray,
          });
          dispatch({
            type: "GET_ALL_FORMER_BROKERS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "ALL_FORMER_BROKERS",
            payload: [],
          });
          dispatch({
            type: "GET_ALL_FORMER_BROKERS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
        console.log("formerBrokersArray", formerBrokerArray);
      });
      dispatch(
        addSnapshotsListners({
          type: "former-brokers-snapshot",
          snapshot: formerBrokerSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_FORMER_BROKERS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const deleteBrokerData =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    try {
      dispatch({
        type: "DELETE_BROKER_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const deleteAdminFunction = firebase
        .functions()
        .httpsCallable("deleteAdminFunction");
      const response = await deleteAdminFunction(data);
      if (response.data.success) {
        dispatch({
          type: "DELETE_BROKER_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "DELETE_BROKER_LOADING",
          payload: {
            loading: false,
            success: false,
            error: response.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "DELETE_BROKER_LOADING",
        payload: { loading: true, success: false, error: err.message },
      });
    }
  };

export const sendBrokerEmailFromAdmin =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SEND_BROKER_EMAIL_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const sendEmailToBrokerFromAdmin = firebase
        .functions()
        .httpsCallable("sendEmailToBroker");
      const result = await sendEmailToBrokerFromAdmin(data);
      if (result.data.success) {
        dispatch({
          type: "SEND_BROKER_EMAIL_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SEND_BROKER_EMAIL_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SEND_BROKER_EMAIL_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const automatedEmailsFeature =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "AUTOMATED_EMAIL_CHECK_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const automatedEmailsFeature = firebase
        .functions()
        .httpsCallable("automatedEmailsFeature");
      const result = await automatedEmailsFeature(data);
      if (result.data.success) {
        dispatch({
          type: "AUTOMATED_EMAIL_CHECK_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "AUTOMATED_EMAIL_CHECK_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "AUTOMATED_EMAIL_CHECK_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };
export const changeBrokerStatus =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "BROKER_STATUS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const changeBrokerStatus = firebase
        .functions()
        .httpsCallable("changeBrokerStatus");
      const result = await changeBrokerStatus(data);
      console.log("result", result);
      if (result.data.success) {
        dispatch({
          type: "BROKER_STATUS_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "BROKER_STATUS_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "BROKER_STATUS_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const changeBrokerRole =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "CHANGE_BROKER_ROLE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const changeBrokerRoles = firebase
        .functions()
        .httpsCallable("changeBrokerRole");
      const result = await changeBrokerRoles(data);
      console.log("result", result);
      if (result.data.success) {
        dispatch({
          type: "CHANGE_BROKER_ROLE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
        window.location.reload();
      } else {
        dispatch({
          type: "CHANGE_BROKER_ROLE_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "CHANGE_BROKER_ROLE_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const getPercentageOfBRokers = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "GET_ALL_BROKER_PERCENTAGE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const orgId = getState().user.currentUser.orgId;
    const firestore = getFirestore();
    const totalRef = firestore
      .collection("organizations")
      .doc(orgId)
      .collection("credits");
    const passRef = totalRef.where("result", "==", "complete");
    const failRef = totalRef.where("result", "==", "notSubmitted");
    const incompleteRef = totalRef.where("result", "==", "incomplete");

    let total = 0;
    let pass = 0;
    let fail = 0;
    let incomplete = 0;

    try {
      let totalSnapshot = totalRef.onSnapshot((snapshot) => {
        total = snapshot.size;
        console.log("total", total);
        updatePercentages();
      });

      passRef.onSnapshot((snapshot) => {
        pass = snapshot.size;
        console.log("pass", pass);
        updatePercentages();
      });

      failRef.onSnapshot((snapshot) => {
        fail = snapshot.size;
        console.log("fail", fail);
        updatePercentages();
      });

      incompleteRef.onSnapshot((snapshot) => {
        incomplete = snapshot.size;
        console.log("incomplete", incomplete);
        updatePercentages();
      });

      function updatePercentages() {
        if (fail > 0) {
          const passPercentage = (pass / total) * 100;
          const failPercentage = (fail / total) * 100;
          const incompletePercentage = (incomplete / total) * 100;
          console.log(
            "percentages",
            passPercentage,
            failPercentage,
            incompletePercentage
          );
          dispatch({
            type: "SET_PERCENTAGE",
            payload: {
              complete: passPercentage,
              incomplete: incompletePercentage,
              fail: failPercentage,
            },
          });
        } else {
          dispatch({
            type: "SET_PERCENTAGE",
            payload: {
              complete: 0,
              incomplete: 0,
              fail: 0,
            },
          });
        }
      }

      dispatch(
        addSnapshotsListners({
          type: "former-brokers-percentage-snapshot",
          snapshot: totalSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_BROKER_PERCENTAGE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const changeRedirectionNextTime =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const firebase = getFirebase();
      const changebrokerRedirection = firebase
        .functions()
        .httpsCallable("changeBrokerRedirection");
      const result = await changebrokerRedirection(data);
      console.log("result", result);
    } catch (err) {
      console.log(err.message);
    }
  };

export const resetBrokerStatusChangeLoading = () => ({
  type: "BROKER_STATUS_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetAutomatedEmailsFeatureLoading = () => ({
  type: "AUTOMATED_EMAIL_CHECK_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetBrokerLoading = () => ({
  type: "CREATE_BROKER_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetDeleteBrokerLoading = () => ({
  type: "DELETE_BROKER_LOADING",
  payload: { loading: false, success: false, error: null },
});
export const resetBrokerReducer = () => ({
  type: "SET_RESET_BROKER_REDUCER",
});

export const setCombineBrokerData = (data) => ({
  type: "SET_COMBINE_BROKERS_DATA",
  payload: data,
});

export const resetSendEmailToBroker = () => ({
  type: "SEND_BROKER_EMAIL_LOADING",
  payload: { loading: false, success: false, error: null },
});
export const resetCreateBrokersInBulkLoading = () => ({
  type: "CREATE_BROKER_IN_BULK_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetChangeBrokerLoading = () => ({
  type: "CHANGE_BROKER_ROLE_LOADING",
  payload: { loading: false, success: false, error: null },
});
