import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const AddGuidelines =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "ADD_GUIDELINES_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const setGuidelines = firebase.functions().httpsCallable("setGuidelines");
      const result = await setGuidelines(data);
      if (result.data.success) {
        dispatch({
          type: "ADD_GUIDELINES_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "ADD_GUIDELINES_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "ADD_GUIDELINES_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const fetchGuidelines = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "GET_GUIDELINES_DOC_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const orgId = getState().user.currentUser.orgId;

    try {
      let guideLinesSnapshot = firestore
        .collection("organizations")
        .doc(orgId)
        .collection("guidelines")
        .onSnapshot(async (guidelines) => {
          let docData;
          for (let i in guidelines.docs) {
            const item = guidelines.docs[i];
            docData = item.data();
            console.log(docData);
          }
          dispatch({
            type: "SET_GUIDELINES_DOC",
            payload: docData,
          });
        });
      dispatch(
        addSnapshotsListners({
          type: "guidelines-snapshot",
          snapshot: guideLinesSnapshot,
        })
      );
      dispatch({
        type: "GET_GUIDELINES_DOC_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_GUIDELINES_DOC_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetAddGuidelinesLoading = () => (dispatch) => {
  dispatch({
    type: "ADD_GUIDELINES_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};
